import React from 'react';
import Helmet from 'react-helmet';

type SeoProps = {
  title: string;
  description: string;
  authorName?: string;
}

const Seo: React.FunctionComponent<SeoProps> = (props) => {
  const htmlAttributes = { lang: 'en' };
  const defaultMeta = [
    { name: 'description', content: props.description },
    { property: 'og:title', content: props.title },
    { property: 'og:description', content: props.description },
    { property: 'og:type', content: 'website' },
    { name: 'twitter:card', content: 'summary' },
    { name: 'twitter:title', content: props.title },
    { name: 'twitter:description', content: props.description },
  ];

  if (props.authorName) {
    defaultMeta.push({ name: 'twitter:creator', content: props.authorName });
  }

  return (
    <Helmet
      htmlAttributes={htmlAttributes}
      title={props.title}
      meta={defaultMeta}
    />
  );
};

export default Seo;
