import React, { AnchorHTMLAttributes } from 'react';

import '../css/tailwind.css';

export type ClassNameDecorator = {
  className?: string;
}

const Heading1: React.FunctionComponent<ClassNameDecorator> = (props) => {
  // const classNames = composeTopLevelClassName(props, );
  const classNames = [props.className].concat('text-5xl font-black mt-12 mb-6 leading-tight').join(' ');
  return (
    <h1 className={classNames} style={{ clear: 'right' }}>{props.children}</h1>
  );
};

const Heading2: React.FunctionComponent<ClassNameDecorator> = (props) => {
  const classNames = [props.className].concat('text-3xl font-black mt-8 mb-4 leading-tight').join(' ');
  return (
    <h2 className={classNames}>{props.children}</h2>
  );
};

const Heading3: React.FunctionComponent<ClassNameDecorator> = (props) => {
  const classNames = [props.className].concat('text-xl font-black mt-8 mb-4 leading-tight').join(' ');
  return (
    <h3 className={classNames}>{props.children}</h3>
  );
};

const Paragraph: React.FunctionComponent<ClassNameDecorator> = (props) => {
  // const classNames = composeTopLevelClassName(props, 'leading-normal my-4 text-grey-800');
  const classNames = [props.className].concat('leading-normal my-4 text-grey-800').join(' ');
  return (
    <p className={classNames}>{props.children}</p>
  );
};

const UnorderedList: React.FunctionComponent<ClassNameDecorator> = (props) => {
  const classNames = [props.className].concat('list-disc leading-normal my-2 pl-8 text-grey-800').join(' ');
  return (
    <ul className={classNames}>{props.children}</ul>
  );
};

const OrderedList: React.FunctionComponent<ClassNameDecorator> = (props) => {
  const classNames = [props.className].concat('list-decimal leading-normal my-2 pl-8 text-grey-800').join(' ');
  return (
    <ol className={classNames}>{props.children}</ol>
  );
};

const ListItem: React.FunctionComponent<ClassNameDecorator> = (props) => (
  <li className="my-4">{props.children}</li>
);

const linkClassNames = [
  'text-red-800 hover:text-gray-800',
  'border-b-2 border-solid',
  'border-gray-500 hover:border-red-500',
  'font-medium',
].join(' ');
const Link: React.FunctionComponent<AnchorHTMLAttributes<{}>> = (props) => {
  const { children, ...otherProps } = props;
  return <a className={linkClassNames} {...otherProps}>{children}</a>;
};

const Emphasis: React.FunctionComponent = (props) => <em className="text-black italic">{props.children}</em>;

const Strong: React.FunctionComponent = (props) => <strong className="text-black font-bold">{props.children}</strong>;

const Strikethrough: React.FunctionComponent = (props) => <span className="text-gray-500 line-through">{props.children}</span>;


const ThematicBreak: React.FunctionComponent = () => <div className="font-mono text-xl text-gray-700 tracking-widest text-strong text-center my-12 not-sr-only">***</div>;

const BlockQuote: React.FunctionComponent = (props) => <blockquote className="bg-gray-200 text-black py-2 px-8 border-l-8 border-gray-700 shadow rounded">{props.children}</blockquote>;

/*
todo:
- blockquote | >

- table, td, tr, th

- pre | `console.log()`
- code | ```console.log()```

- img | ![alt](https://mdx-logo.now.sh)
 */

// todo: praragraph only containing children...

const DesignSystem = {
  Headings: {
    H1: Heading1,
    H2: Heading2,
    H3: Heading3,
  },
  TextStyle: {
    Strikethrough,
    Strong,
    Emphasis,
  },
  Lists: {
    OrderedList,
    UnorderedList,
    ListItem,
  },
  Paragraph,
  Link,
};

export default DesignSystem;

export const DesignSystemMdxComponents = {
  h1: DesignSystem.Headings.H1,
  h2: DesignSystem.Headings.H2,
  h3: DesignSystem.Headings.H3,
  p: DesignSystem.Paragraph,
  a: DesignSystem.Link,

  ol: DesignSystem.Lists.OrderedList,
  ul: DesignSystem.Lists.UnorderedList,
  li: DesignSystem.Lists.ListItem,

  blockquote: BlockQuote,

  em: DesignSystem.TextStyle.Emphasis,
  strong: DesignSystem.TextStyle.Strong,
  delete: DesignSystem.TextStyle.Strikethrough,

  hr: ThematicBreak,
};
