import React from 'react';
import { Link } from 'gatsby';

import '../css/tailwind.css';

const footerClassName = [
  'py-4',
  'bg-gray-200',
  'text-center',
].join(' ');

const linkClassName = [
  'font-medium',
  'no-underline hover:underline',
  'text-black hover:text-red-600',
].join(' ');

const Footer: React.FunctionComponent = () => (
  <footer className={footerClassName}>
    <p>All rights reserved {new Date().getFullYear()}</p>
    <Link to="/legal" className={linkClassName}>Legal</Link>
  </footer>
);

export default Footer;
