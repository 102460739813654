import React from 'react';
import { Link } from 'gatsby';

import '../css/tailwind.css';

const headerClassNames = [
  'container mx-auto',
  'px-4 sm:px-8',
  'py-4',
  'flex flex-row flex-no-wrap',
  'items-center justify-start',
].join(' ');

const Header: React.FunctionComponent = () => {
  // todo: extract the below content into a configuration file
  const punchLine = '<JustJuzzy/>';
  const logoElement = <div className="flex-1 flex-no-shrink">
    <Link
      className="font-mono font-black text-3xl text-black no-underline"
      to="/">{punchLine}</Link>
    <div className="text-grey-darker text-sm">Alternative simpler explanations to software architecture and design
    </div>
  </div>;

  return (
    <div className="bg-white shadow-md relative z-50">
      <header className={headerClassNames}>
        {logoElement}
      </header>
    </div>
  );
};

export default Header;
